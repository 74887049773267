import React from 'react';
import './styles.css';


export default function Footer() {


  return (
    <div className='footer'>
      <img
        src={process.env.PUBLIC_URL + '/images/aidafyLogo.png'}
        className="footerLogo"
        alt='Aidafy'
      ></img>
      <p>info@aidafy.com</p>
      <p>®Aidafy</p>
    </div>
  );
}
